<template>
  <div class="not-found primary">
    <div class="text-center white--text content">
      <img class="d-block mx-auto" src="@/assets/img/notFound.svg" alt="img" />
      <v-divider class="line"></v-divider>
      <div class="title-text">{{ $t('notFound.title') }}</div>
      <div class="text-sm-h5">{{ $t('notFound.desc') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>

<style lang="scss">
.not-found {
  min-height: calc(100vh - 60px);
  img {
    object-fit: cover;
    max-width: 535px;
    width: 100%;
  }
  .content {
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .line {
    position: relative;
    bottom: 14px;
    height: 7px;
    max-height: 7px;
    width: 100vw;
    background: #181c3a;
  }
  .title-text {
    font-size: 64px;
    font-weight: 700;
  }
  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 42px);
  }
  @media screen and (max-width: 560px) {
    img {
      padding: 0 16px;
    }
    .title-text {
      font-size: 42px;
      font-weight: 700;
    }
  }
}
</style>
